.sticky-table {
  display: block;
  position: relative;
  overflow: scroll;
  border-collapse: collapse;
  font-size: 0;
}
.sticky-table thead,
.sticky-table tbody {
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.sticky-table th,
.sticky-table td {
  display: inline-block;
  font-size: 1.0rem;
}
.sticky-table th:not(.table-success):not(.table-danger) {
  background-color: #fff;
}
.sticky-table tbody th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.sticky-table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.sticky-table thead th:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
}
.sticky-table thead th .fas {
  line-height: 0;
}
