@import './sticky-table.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

::placeholder {
  color: #bbb !important;
}

input.time-only {
  width: 100px;
}

input[type="number"] {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.pre-line {
  white-space: pre-line;
}

.text-very-muted {
  color: #b5b5b5;
}
